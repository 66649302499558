<template>
  <b-overlay
    class="overlay-rounded-lg min-h-150px"
    :show="loadingDetail || loadingSubmit"
  >
    <b-row
      v-if="data && (data.can_pickup_transaction || data.can_arrive_transaction || data.can_complete_transaction || data.can_print_packing_label || data.can_cancel_transaction)"
      no-gutters
      class="mb-1 d-none d-sm-flex"
    >
      <b-col>
        <b-form-row class="justify-content-end">
          <!-- Main Actions -->
          <b-col
            v-if="data.can_pickup_transaction"
            cols="auto"
          >
            <b-button
              variant="primary"
              class="pl-1"
              @click="requestPickup"
            >
              <feather-icon
                icon="CheckIcon"
                size="16"
                class="mr-50"
              />
              Tandai telah dikirim
            </b-button>
          </b-col>
          <b-col
            v-if="data.can_arrive_transaction"
            cols="auto"
          >
            <b-button
              variant="primary"
              class="pl-1"
              @click="requestArrive"
            >
              <feather-icon
                icon="CheckIcon"
                size="16"
                class="mr-50"
              />
              Tandai telah sampai
            </b-button>
          </b-col>
          <b-col
            v-if="data.can_complete_transaction"
            cols="auto"
          >
            <b-button
              variant="primary"
              class="pl-1"
              @click="requestComplete"
            >
              <feather-icon
                icon="CheckIcon"
                size="16"
                class="mr-50"
              />
              Tandai Selesai
            </b-button>
          </b-col>
          <b-col
            v-if="(data.can_pickup_transaction || data.can_arrive_transaction || data.can_complete_transaction) && (data.can_print_packing_label)"
            cols="auto"
          >
            <span class="d-block border-right border-right-2 h-100" />
          </b-col>
          <!-- Secondary Actions -->
          <b-col
            v-if="data.can_print_packing_label"
            cols="auto"
          >
            <b-button
              class="pl-1"
              @click="requestPrint"
            >
              <feather-icon
                icon="PrinterIcon"
                size="16"
                class="mr-50"
              />
              Cetak Label
            </b-button>
          </b-col>
        </b-form-row>
      </b-col>

      <!-- Other Actions -->
      <b-col
        v-if="data.can_cancel_transaction"
        cols="auto"
      >
        <b-dropdown
          variant="white"
          no-caret
          toggle-class="px-1"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
            />
          </template>
          <b-dropdown-item
            v-if="data.can_cancel_transaction"
            @click="requestCancel"
          >
            <span class="text-danger">Tolak pesanan</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <b-row v-if="data">
      <b-col md="8">
        <!-- Summary -->
        <b-card>
          <b-row>
            <b-col>
              <h4 class="mb-0">
                Ringkasan
              </h4>
              <TableField
                class="mb-1 mb-md-0"
                :value="data.invoice_number"
                show-copy
              />
            </b-col>

            <b-col
              cols="auto"
              class="d-sm-none mr-n75 mt-n75"
            >
              <b-dropdown
                variant="white"
                no-caret
                toggle-class="px-1"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                  />
                </template>

                <b-dropdown-item
                  v-if="data.can_pickup_transaction"
                  @click="requestPickup"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="mr-50"
                  />
                  Tandai telah dikirim
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="data.can_arrive_transaction"
                  @click="requestArrive"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="mr-50"
                  />
                  Tandai telah sampai
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="data.can_complete_transaction"
                  @click="requestComplete"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="mr-50"
                  />
                  Tandai Selesai
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="data.can_print_packing_label"
                  @click="requestPrint"
                >
                  <feather-icon
                    icon="PrinterIcon"
                    size="16"
                    class="mr-50"
                  />
                  Cetak Label
                </b-dropdown-item>

                <b-dropdown-divider v-if="data.can_cancel_transaction" />

                <b-dropdown-item
                  v-if="data.can_cancel_transaction"
                  @click="requestCancel"
                >
                  <span class="text-danger">Tolak pesanan</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-col>

            <b-col
              sm="auto"
              class="d-flex flex-column align-items-sm-end"
            >
              <TableField
                class="h4"
                type="badge"
                :text="data.status_text"
                :map="map"
                :value="data.status"
              />

              <div v-if="data.status === 'rejected' || data.status === 'cancelled'">
                Alasan: {{ data.reason || '-' }}
              </div>
            </b-col>
          </b-row>

          <hr>

          <b-row class="flex-md-row-reverse">
            <!-- Purchase or pay order time -->
            <b-col
              md
              class="text-md-right"
            >
              <dl>
                <dt>
                  Dibayar pada
                </dt>
                <dd>
                  <TableField
                    class="justify-content-md-end"
                    type="datetime"
                    :value="data.created_at"
                  />
                </dd>
              </dl>
            </b-col>

            <!-- Buyer -->
            <b-col
              md
              class="mb-25"
            >
              <dl>
                <dt class="mb-50">
                  Pembeli
                </dt>
                <dd>
                  <TableField
                    type="profile"
                    :value="data.user.name"
                    :image="data.user.image_url"
                    :url="{
                      callback: () => `/user/${data.user.id}`,
                    }"
                  />
                </dd>
              </dl>
            </b-col>
          </b-row>

          <dl>
            <dt>
              Penerima
            </dt>
            <dd>
              {{ data.destination.consignee_name }} ({{ data.destination.phone | phone }})
              <div class="mt-25">
                <small>{{ data.destination.full_address }}</small>
              </div>
            </dd>
          </dl>
        </b-card>

        <!-- Produk -->
        <b-card>
          <h4>
            Produk
          </h4>
          <p class="mb-2">
            {{ data.warehouse.brand.name }}
          </p>

          <div
            v-for="(item, index) in data.transaction_items"
            :key="`transaction-item-${item.id}`"
            :class="{ 'border-top mt-1 pt-2': index > 0 }"
          >
            <b-row no-gutters>
              <b-col cols="auto">
                <img
                  :src="item.image_url | thumbnail(64)"
                  alt="Product Image"
                  class="rounded border mr-2"
                >
              </b-col>
              <b-col>
                <h6>{{ item.product.name }}</h6>

                <table>
                  <tbody>
                    <tr v-if="item.sku.variant">
                      <td class="text-capitalize">
                        {{ item.sku.variant.label }}
                      </td>
                      <td class="pl-2 pr-1">
                        :
                      </td>
                      <td>{{ item.sku.variant.name }}</td>
                    </tr>
                    <tr v-if="item.sku.sub_variant">
                      <td class="text-capitalize">
                        {{ item.sku.sub_variant.label }}
                      </td>
                      <td class="pl-2 pr-1">
                        :
                      </td>
                      <td>{{ item.sku.sub_variant.name }}</td>
                    </tr>
                    <tr>
                      <td class="text-capitalize">
                        Jumlah
                      </td>
                      <td class="pl-2 pr-1">
                        :
                      </td>
                      <td>{{ item.quantity }}</td>
                    </tr>
                  </tbody>
                </table>

                <div class="font-weight-bold mt-50">
                  Rp {{ (item.sum_price || (item.quantity * item.price)) | number }}
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>

      <b-col md="4">
        <!-- Delivery -->
        <b-card>
          <h4 class="mb-2">
            Pengiriman
          </h4>

          <dl class="row align-items-center">
            <dt class="col">
              Kurir
            </dt>
            <dd class="col-auto mb-0">
              <TableField
                :value="data.courier_name"
              />
            </dd>
          </dl>

          <dl class="row align-items-center">
            <dt class="col">
              Layanan
            </dt>
            <dd class="col-auto mb-0">
              <TableField
                :value="`${data.courier_service.name} (${data.courier_service.code})`"
              />
            </dd>
          </dl>

          <dl class="row align-items-center mb-0">
            <dt class="col">
              No Resi
            </dt>
            <dd class="col-auto mb-0">
              <TableField
                class="my-n25"
                :value="data.awb_number"
                show-copy
              />
            </dd>
          </dl>
        </b-card>

        <!-- Detail -->
        <b-card v-if="$store.state.settings.settings.can_have_multiple_warehouses === 'true'">
          <h4 class="mb-2">
            Rincian
          </h4>

          <dl class="row align-items-center">
            <dt class="col">
              Total Produk
            </dt>
            <dd class="col-auto mb-0">
              Rp {{ data.product_total | number }}
            </dd>
          </dl>

          <dl class="row align-items-center">
            <dt class="col">
              Biaya Pengiriman
            </dt>
            <dd class="col-auto mb-0">
              Rp {{ data.delivery_total | number }}
            </dd>
          </dl>

          <hr>

          <dl class="row align-items-center mb-0">
            <dt class="col">
              Total Belanja
            </dt>
            <dd class="col-auto mb-0">
              Rp {{ data.transaction_total | number }}
            </dd>
          </dl>
        </b-card>

        <PaymentCard
          v-else
          :data="data"
        />
      </b-col>
    </b-row>

    <hr
      v-if="$store.state.settings.settings.can_have_multiple_warehouses === 'true' && data"
      class="mt-0 mb-1 pb-1"
    >

    <b-row v-if="$store.state.settings.settings.can_have_multiple_warehouses === 'true' && data">
      <b-col md="8">
        <!-- Transactions -->
        <b-card>
          <h4 class="mb-2">
            Pembayaran ({{ data.payment.transactions.length }} Invoice)
          </h4>

          <div
            v-for="(item, index) in data.payment.transactions"
            :key="`transaction-item-${item.id}`"
            :class="{ 'border-top mt-1 pt-2': index > 0 }"
          >
            <b-form-row>
              <b-col cols="auto">
                <h6>{{ index + 1 }}.</h6>
              </b-col>
              <b-col>
                <div v-if="item.id === transactionId">
                  <b-form-row class="align-items-center">
                    <b-col cols="auto">
                      <h6>{{ item.invoice_number }}</h6>
                    </b-col>
                    <b-col class="mt-n50 d-flex">
                      <b-badge
                        variant="light-secondary"
                      >
                        Transaksi ini
                      </b-badge>
                    </b-col>
                  </b-form-row>
                </div>
                <div v-else>
                  <h6><router-link :to="`/transaction/${item.id}`">
                    {{ item.invoice_number }}
                  </router-link></h6>
                </div>

                <b-row>
                  <b-col>
                    <span>{{ item.warehouse.brand.name }}</span>
                  </b-col>
                  <b-col cols="auto">
                    <span>Rp {{ item.transaction_total | number }}</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-form-row>
          </div>
        </b-card>
      </b-col>

      <b-col md="4">
        <!-- Payment -->
        <PaymentCard :data="data" />
      </b-col>
    </b-row>

    <!-- Pickup modal -->
    <b-modal
      v-model="pickupModalVisible"
      title="Tandai telah dikirim"
      ok-title="Simpan"
      cancel-variant="white"
      :ok-disabled="$store.state.transaction.loadingSubmit"
      @ok.prevent="$refs.pickupSubmit.click()"
    >
      <b-form
        @submit.prevent="pickup"
      >
        <b-form-group label="No Resi Pengiriman*">
          <b-form-input
            v-model="awbNumber"
            required
          />
        </b-form-group>

        <button
          ref="pickupSubmit"
          type="submit"
          hidden
        />
      </b-form>
    </b-modal>

    <!-- Cancel modal -->
    <b-modal
      v-model="cancelModalVisible"
      title="Tolak pesanan"
      ok-variant="danger"
      ok-title="Tolak pesanan"
      cancel-variant="white"
      :ok-disabled="$store.state.transaction.loadingSubmit"
      @ok.prevent="$refs.cancelSubmit.click()"
    >
      <p>Pesanan ini akan ditolak dan stok produk akan dikembalikan.</p>
      <small>Catatan: Harap lakukan pengembalian dana kepada pembeli.</small>

      <hr>
      <b-form
        @submit.prevent="cancel"
      >
        <b-form-group label="Alasan pembatalan*">
          <b-form-textarea
            v-model="reason"
            required
          />
        </b-form-group>

        <button
          ref="cancelSubmit"
          type="submit"
          hidden
        />
      </b-form>
    </b-modal>

    <!-- Arrive modal -->
    <b-modal
      v-model="arriveModalVisible"
      title="Tandai telah sampai"
      ok-title="Ya, yakin"
      cancel-variant="white"
      :ok-disabled="$store.state.transaction.loadingSubmit"
      @ok="arrive"
    >
      Pesanan ini akan ditandai telah sampai ke penerima. Apakah Anda yakin?
    </b-modal>

    <!-- Complete modal -->
    <b-modal
      v-model="completeModalVisible"
      title="Tandai selesai"
      ok-title="Ya, yakin"
      cancel-variant="white"
      :ok-disabled="$store.state.transaction.loadingSubmit"
      @ok="complete"
    >
      Pesanan ini akan ditandai telah selesai. Apakah Anda yakin?
    </b-modal>

    <!-- Print packing setting label modal -->
    <b-modal
      v-model="printSettingModalVisible"
      title="Pengaturan Label"
      ok-title="Selesai"
      ok-only
      :ok-disabled="$store.state.transaction.loadingSubmit"
      @hide="requestPrint"
    >
      <b-row v-if="data">
        <b-col cols="12">
          <b-form-group
            label="Ukuran kertas:"
            class="mb-75"
            label-cols="5"
          >
            <b-form-radio-group
              v-model="paperSize"
              class="mt-50"
              :options="optionsPaperSize"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Produk:"
            class="mb-75"
            label-cols="5"
          >
            <b-form-checkbox
              v-model="isShowProduct"
              class="mt-50"
              inline
            >
              Tampilkan produk
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col
          v-if="data.awb_number"
          cols="12"
        >
          <b-form-group
            label="Barcode resi:"
            class="mb-75"
            label-cols="5"
          >
            <b-form-checkbox
              v-model="isShowBarcode"
              class="mt-50"
              inline
            >
              Tampilkan barcode resi
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Print packing label modal -->
    <b-modal
      v-model="printModalVisible"
      title="Cetak Label"
    >
      <div v-if="data">
        <PackingLabel
          :data="data"
          :size="paperSize"
          :show-barcode="isShowBarcode"
          :show-product="isShowProduct"
        />
      </div>

      <template #modal-footer>
        <b-form-row class="w-100 align-items-center mx-n25 flex-column-reverse flex-md-row">
          <b-col
            cols="12"
            md
          >
            <div
              class="d-flex align-items-center text-primary cursor-pointer py-1 w-100 justify-content-center justify-content-md-start mt-25 mt-md-0"
              @click="requestSetting"
            >
              <feather-icon
                icon="SettingsIcon"
                size="16"
                class="mr-50"
              />
              Pengaturan
            </div>
          </b-col>
          <b-col
            cols="auto"
            class="d-none d-md-block"
          >
            <b-button
              variant="white"
              @click="printModalVisible = false"
            >
              Batal
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="auto"
          >
            <b-button
              variant="primary"
              block
              @click="print"
            >
              <feather-icon
                icon="PrinterIcon"
                size="16"
                class="mr-50"
              />
              Cetak Label
            </b-button>
          </b-col>
        </b-form-row>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import TableField from '@/layouts/components/TableField.vue'
import PaymentCard from '@/layouts/components/PaymentCard.vue'
import PackingLabel from '@/layouts/components/PackingLabel.vue'
import { TransactionStatus } from '@/map/status'

import {
  BForm,
  BBadge,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BFormRow,
  BCard,
  BRow,
  BCol,
  BButton,
  BFormRadioGroup,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BDropdownDivider, BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    PaymentCard,
    PackingLabel,
    BOverlay,
    BForm,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormRow,
    BFormRadioGroup,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    BButton,
    BDropdown,
    BDropdownItem,
    TableField,
    BDropdownDivider,
  },
  data() {
    return {
      transactionId: this.$route.params.id,
      cancelModalVisible: false,
      pickupModalVisible: false,
      arriveModalVisible: false,
      completeModalVisible: false,

      printModalVisible: false,
      printSettingModalVisible: false,

      loadingDetail: false,
      loadingSubmit: false,

      paperSize: 'a4',
      optionsPaperSize: [
        {
          text: 'A4',
          value: 'a4',
        },
        {
          text: 'A6',
          value: 'a6',
        },
      ],
      isShowProduct: true,
      isShowBarcode: true,

      awbNumber: '',
      reason: '',

      map: TransactionStatus,
    }
  },
  computed: {
    data() {
      return this.$store.getters['transaction/detailData']
    },
  },
  watch: {
    $route(value) {
      this.transactionId = value.params.id
      this.loadData(this.transactionId)
    },
  },
  created() {
    this.$store.commit('transaction/SET_DETAIL_DATA', null)
  },
  mounted() {
    this.loadData(this.transactionId)
  },
  methods: {
    requestSetting() {
      this.printModalVisible = false
      this.printSettingModalVisible = true
    },
    requestPrint() {
      this.printModalVisible = true
    },
    print() {
      window.print()
    },
    loadData(id) {
      this.loadingDetail = true

      return this.$store.dispatch('transaction/getDetail', id)
        .finally(() => {
          this.loadingDetail = false
        })
    },
    requestCancel() {
      this.cancelModalVisible = true
    },
    requestPickup() {
      this.pickupModalVisible = true
    },
    requestArrive() {
      this.arriveModalVisible = true
    },
    requestComplete() {
      this.completeModalVisible = true
    },
    pickup() {
      return this.$store.dispatch('transaction/pickup', {
        id: this.transactionId,
        awbNumber: this.awbNumber,
      })
        .then(() => {
          this.awbNumber = ''
          this.pickupModalVisible = false
          this.loadData(this.transactionId)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Berhasil',
              icon: 'CheckIcon',
              text: 'Pesanan ditandai telah dikirim',
              variant: 'success',
            },
          })
        })
    },
    arrive() {
      return this.$store.dispatch('transaction/arrive', this.transactionId)
        .then(() => {
          this.arriveModalVisible = false
          this.loadData(this.transactionId)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Berhasil',
              icon: 'CheckIcon',
              text: 'Pesanan ditandai telah sampai',
              variant: 'success',
            },
          })
        })
    },
    complete() {
      return this.$store.dispatch('transaction/complete', this.transactionId)
        .then(() => {
          this.completeModalVisible = false
          this.loadData(this.transactionId)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Berhasil',
              icon: 'CheckIcon',
              text: 'Pesanan ditandai telah selesai',
              variant: 'success',
            },
          })
        })
    },
    cancel() {
      return this.$store.dispatch('transaction/cancel', {
        id: this.transactionId,
        reason: this.reason,
      })
        .then(() => {
          this.reason = ''
          this.cancelModalVisible = false
          this.loadData(this.transactionId)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Berhasil',
              icon: 'CheckIcon',
              text: 'Pesanan telah ditolak',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@page {
  size: A4;
  margin: 0;
}

@media print {
  @media print {
    @page { size: portrait }
  }

  // Global Styles, A4 paper
  html, body {
    width: 210mm;
    height: 297mm;
  }

  body {
    background-color: transparent !important;
  }

  #app {
    display: none;
  }

  .modal {
    height: fit-content !important;

    .modal-header,
    .modal-footer {
      display: none;
    }

    .modal-dialog {
      margin: 0;
      max-width: unset !important;
    }

    .modal-content {
      box-shadow: unset !important;
    }

    .modal-body {
      padding: 0 !important;

      .label-box {
        &.a4 {
          width: 50%;
        }

        &.a6 {
          width: 100%;
        }
      }
    }
  }

  .modal-backdrop {
    display: none;
  }
}
</style>
