<template>
  <b-card v-if="data">
    <b-row
      align-v="center"
      class="mb-2"
    >
      <b-col>
        <h4 class="mb-0">
          Pembayaran
        </h4>
      </b-col>
      <b-col
        v-if="data.payment.gateway_payment_response || data.payment.gateway_payment_id"
        cols="auto"
      >
        <b-dropdown
          variant="white"
          no-caret
          toggle-class="px-1 my-n1 mr-n1"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
            />
          </template>
          <b-dropdown-item
            v-if="data.payment.gateway_payment_id"
            :href="`${midtransBaseUrl}/beta/transactions?detail=${data.payment.gateway_payment_id}`"
            target="_blank"
          >
            Buka di Midtrans
          </b-dropdown-item>
          <!-- When there is no gateway response, means user hasn't open the midtrans snap UI yet -->
          <b-dropdown-item
            v-if="data.payment.gateway_payment_response"
            target="_blank"
            :href="`${midtransBaseUrl}/settings/vtweb_configuration/history?q[created_after]=&q[created_before]=&q[order_id]=${data.payment.id}&q[status]=&q[kind]=&commit=Search`"
          >
            Notifikasi Webhook
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <dl class="row align-items-center">
      <dt class="col">
        Total Produk
      </dt>
      <dd class="col-auto mb-0">
        Rp {{ data.payment.product_total | number }}
      </dd>
    </dl>

    <dl class="row align-items-center">
      <dt class="col">
        <span v-if="$store.state.settings.settings.can_have_multiple_warehouses === 'true'">Total</span>
        <span v-else>Biaya</span>
        Pengiriman
      </dt>
      <dd class="col-auto mb-0">
        Rp {{ data.payment.delivery_total | number }}
      </dd>
    </dl>

    <hr v-if="data.payment.voucher">

    <dl
      v-if="data.payment.voucher"
      class="row align-items-center"
    >
      <dt class="col">
        <h5 class="mb-0">
          <b-badge variant="light-success">
            {{ data.payment.voucher.title }}
          </b-badge>
        </h5>
        <div class="mt-25">
          <small>
            <span v-if="data.payment.product_discount">Diskon produk </span>
            <span v-else-if="data.payment.delivery_discount">Diskon ongkir </span>
            <span>{{ data.payment.voucher.percentage | number }}% </span>
            hingga Rp <span>{{ data.payment.voucher.maximum | number }}</span>
          </small>
        </div>
      </dt>
      <dd class="col-auto mb-0">
        - Rp {{ data.payment.product_discount | number }}
      </dd>
    </dl>

    <hr>

    <dl
      class="row align-items-center mb-0"
    >
      <dt class="col">
        Total Tagihan
      </dt>
      <dd class="col-auto mb-0">
        Rp {{ data.payment.billed_total | number }}
      </dd>
    </dl>

    <hr>

    <dl class="mb-0">
      <dt class="mb-50">
        Metode Pembayaran
      </dt>
      <dd>
        <TableField
          type="profile"
          :value="data.payment.payment_method.name"
          :image="data.payment.payment_method.image_url"
        />
      </dd>
    </dl>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import TableField from '@/layouts/components/TableField.vue'

export default {
  components: {
    BBadge,
    BCard,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    TableField,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      midtransBaseUrl: process.env.VUE_APP_MIDTRANS_BASE_URL,
    }
  },
}
</script>

<style scoped>

</style>
