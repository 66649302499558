<template>
  <div class="label-container">
    <div
      class="label-box"
      :class="size"
    >
      <!-- Header -->
      <div class="pt-1 pb-0 px-1 mb-75">
        <b-row align-v="end">
          <!-- Header: Left Content -->
          <b-col>
            <div class="logo-wrapper">
              <b-img
                src="@/assets/images/logo/logo-w-label.png"
                alt="logo"
                width="120px"
              />
            </div>
            <p class="card-text">
              {{ data.invoice_number }}
            </p>
          </b-col>
          <!-- Header: Right Content -->
          <b-col
            cols="auto"
          >
            <div
              class="mt-md-0 mt-2 text-right"
              :class="{ 'pl-1': data.delivery_insurance_fee, 'px-1': !data.delivery_insurance_fee }"
            >
              <b class="text-uppercase">{{ data.courier_name }}</b>
              <div>{{ data.courier_service_code }}</div>
              <div
                v-if="data.delivery_insurance_fee"
                class="mt-25"
              >
                <small>Asuransi: {{ toCurrency(data.delivery_insurance_fee) }}</small>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Spacer -->
      <hr
        v-if="showBarcode && data.awb_number"
        class="mb-50 mt-25"
      >

      <div
        v-if="showBarcode && data.awb_number"
        class="text-center"
      >
        <div>Airwaybill Number</div>
        <barcode
          :value="data.awb_number"
          width="2"
          height="40"
          font-size="14"
        />
      </div>

      <!-- Spacer -->
      <hr
        class="mb-0"
        :class="{'mt-25': data.awb_number}"
      >

      <!-- Packing Label Client & Merchant -->
      <div
        class="px-1 py-1"
      >
        <b-row>
          <!-- Col: Packing Label To -->
          <b-col
            cols="7"
          >
            <h6 class="mb-50 text-uppercase s-12 font-weight-bolder">
              Kepada:
            </h6>
            <div class="font-weight-bold mb-25 s-14">
              <span class="text-wrap">{{ data.destination.consignee_name }}</span>
              <span v-if="data.destination.phone" class="text-nowrap"> ({{ data.destination.phone | phone }})</span>
            </div>
            <span class="s-12">
              {{ data.destination.full_address }}
            </span>
          </b-col>
          <!-- Col: Payment Details -->
          <b-col
            cols="5"
          >
            <div>
              <h6 class="mb-50 text-uppercase s-12 font-weight-bolder">
                Dari:
              </h6>
              <div class="font-weight-bold mb-25 s-14">
                {{ data.warehouse.brand.name }}
              </div>
              <span class="s-12 line-height-1">
                {{ data.warehouse.district.name }}, {{ data.warehouse.city.name }}
              </span>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Spacer -->
      <hr class="mt-0 mb-50">

      <!-- Packing Label Product: Table -->
      <div
        v-show="showProduct"
        class="px-1 pt-25 pb-1"
      >
        <b-table-simple
          class="mb-0"
          borderless
        >
          <b-thead class="light-header">
            <b-tr>
              <b-th class="text-left pl-0 pb-0 pt-0">
                Produk
              </b-th>
              <b-th class="text-right pr-0 pb-0 pt-0">
                Jumlah
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(item, index) in data.transaction_items"
              :key="`item-data-${index}`"
            >
              <b-td class="pl-0 pb-0">
                <div class="d-flex">
                  <div class="mr-1">
                    {{ index + 1 }}.
                  </div>
                  <dl class="mb-0">
                    <dt class="font-weight-bold">{{ item.product.name }}</dt>
                    <small>
                      <span v-if="item.sku.variant">{{ item.sku.variant.name }}</span>
                      <span v-if="item.sku.sub_variant"> • {{ item.sku.sub_variant.name }}</span>
                    </small>
                  </dl>
                </div>
              </b-td>
              <b-td class="text-right pb-0 pr-0 d-flex">
                <div class="d-flex justify-content-end ml-auto">
                  {{ item.quantity | number }} pcs
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>

      <!-- Note -->
      <div
        class="pt-0 pb-1 px-1"
        :class="showProduct ? '' : 'mt-1'"
      >
        <p class="mb-0 s-12">
          Catatan: Wajib merekam proses unboxing untuk mengajukan komplain.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BTableSimple,
  BTr,
  BTbody,
  BTd,
  BThead,
  BTh,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import VueBarcode from 'vue-barcode'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BTableSimple,
    BTr,
    BTbody,
    BTd,
    BThead,
    BTh,
    barcode: VueBarcode,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'a4',
    },
    showProduct: {
      type: Boolean,
      default: true,
    },
    showBarcode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      logo: $themeConfig.app.appLogoImage,
    }
  },
}
</script>

<style lang="scss" scoped>
.label-container {
  overflow-x: auto;
  background-color: #FFFFFF;
  color: #222222;
}

.label-container hr {
  border-top: 1px solid #ebe9f1;
}

.label-container table {
  background-color: #FFFFFF;
}

.label-container h6,
.label-container table th,
.label-container table td {
  color: #222222 !important;
}

.label-box {
  min-width: 460px;
  border: 2px dotted #888888;
}

.light-header {
  th {
    background-color: transparent !important;
  }
}

.s-12 {
  font-size: 12px;
}

.s-16 {
  font-size: 16px;
}
</style>
